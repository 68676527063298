<template>
  <div class="vld-parent">
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="row" style="min-height: 300px">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h4 class="card-title">VIN</h4>
            Get car report
          </template>
          <div style="min-height: 300px">
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                  <div class="row" style="display: table">
                    <div class="col-sm-8 middle">
                      <fg-input
                        placeholder="VIN"
                        v-model="search"
                        v-validate="modelValidations.vin"
                        name="VIN"
                        :error="getError('VIN')"
                      ></fg-input>
                    </div>
                    <div class="col-sm-4 middle">
                      <n-button type="info" block @click.native="validate">
                        Go
                      </n-button>
                    </div>
                  </div>
                  <div class="row" v-if="result.valid === 2">
                    <div class="col-12">
                      <h3>VIN is invalid!</h3>
                    </div>
                  </div>
                  <div v-if="result.valid === 1">
                    <div class="row bottom-5">
                      <div class="col-5"><b>Brand:</b></div>
                      <div class="col-7">{{ result.manufacturer }}</div>
                    </div>
                    <div class="row bottom-5">
                      <div class="col-5"><b>Region/Plant:</b></div>
                      <div class="col-7">{{ result.country }}</div>
                    </div>
                    <div class="row bottom-5">
                      <div class="col-5"><b>Year:</b></div>
                      <div class="col-7">{{ result.year }}</div>
                    </div>
                    <div class="row bottom-5">
                      <div class="col-5"><b>Check digit:</b></div>
                      <div class="col-7">{{ result.checkdigit }}</div>
                    </div>
                    <div class="row">
                      <div class="col-5"><b>Sequential number:</b></div>
                      <div class="col-7">{{ result.seqnumber }}</div>
                    </div>

                    <div v-if="vin.status === 1">
                      <div class="row">
                        <div class="col-12">
                          <h4>
                            Accident report (Total {{ vin.accident.length }})
                          </h4>
                        </div>
                      </div>
                      <card
                        v-for="item in vin.accident"
                        :key="item.id"
                        style="margin-bottom: 15px"
                      >
                        <div class="row">
                          <div class="col-5">Insurance type</div>
                          <div class="col-7">
                            <b>{{ item.policytypename }}</b>
                          </div>
                          <div class="col-5">Date</div>
                          <div class="col-7">
                            <b>{{ item.accidentdate }}</b>
                          </div>

                          <div class="col-5">Amount of damage</div>
                          <div class="col-7">₼ {{ item.amount }}</div>

                          <div class="col-5">Lavel</div>
                          <div class="col-7">{{ item.levelname }}</div>

                          <div class="col-5">Description</div>
                          <div class="col-7">{{ item.descr }}</div>
                        </div>
                      </card>
                    </div>
                    <div class="row" v-else>
                      <div class="col-12">
                        <h4>
                          {{ vin.msg }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                </div>
              </div>
            </form>
          </div>
        </card>
        <!-- end card -->
      </div>
    </div>

  </div>
</template>
<script>
import swal from "sweetalert2";
import { Modal } from "src/components";
import { VinDecode } from "src/services/vindecoder";
import VintService from "src/services/vin";
import { FormGroupInput as FgInput } from "src/components/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option,
} from "element-ui";
export default {
  components: {
    FgInput,
    Loading,
    Modal,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      isLoading: false,
      search: "",
      result: {
        valid: 0,
      },
      vin: {
        accident: [],
        status: 0,
        msg: "",
      },
      recent: [],
      accident: {
        level: 1,
      },
      modal: false,
      editvin: "",
      resultvin: {
        valid: 0,
      },
      modelValidations: {
        vin: {
          required: true,
        },
        vinmodal: {
          required: true,
        },
        amount: {
          required: true,
          min_value: 1,
          max_value: 10000,
          numeric: true,
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.result.valid = 0;
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.result = VinDecode(this.search);
          if (this.result.valid) {
            this.isLoading = true;
            VintService.getVinAccident(
              this.search,
              this.$store.getters.demouser
            ).then((response) => {
              this.isLoading = false;

              if (response.data.result) {
                if (response.data.result === "-1") {
                  this.vin.status = 2;
                  this.vin.msg = "You don't have permissions!";
                } else {
                  this.vin.status = 1;
                  this.vin.accident = response.data.result;
                }
              } else {
                this.vin.status = 3;
                this.vin.msg = "No data found!";
              }
            });
          }
        }
      });
    },
  },
  mounted() {
  },
  watch: {
    editvin: function (value) {
      this.resultvin = VinDecode(value);
      if (!this.resultvin) this.resultvin = { valid: 0 };
    },
  },
};
</script>
<style>
.middle {
  vertical-align: middle;
  display: table-cell;
}
.top {
  vertical-align: top;
  display: table-cell;
}
.bottom-5 {
  margin-bottom: 5px;
}
.font-size-12 {
  font-size: 12px;
}
</style>