import axios from "axios";
import Vue from "vue";

const client = axios.create({
  baseURL: Vue.prototype.$apiUrl,
  withCredentials: false
});

client.interceptors.request.use(config => {
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

export default {
  getVinAccident(vin, uid) {
    const params = {
      vin: vin,
      uid: uid,
      action: "search"
    };
    return client.get("vin/vin.php", { params });
  },

  getVinAccidentRecent(uid) {
    const params = {
      uid: uid,
      action: "recent"
    };
    return client.get("vin/vin.php", { params });
  },

  insertVinAccident(item) {
    return client.post("vin/vin.php", item);
  },

  updateVinAccident(item) {
    return client.put("vin/vin.php", item);
  },

  deleteVinAccident(item) {
    const params = {
      uid: item.uid,
      id: item.id
    };
    return client.delete("vin/vin.php", params);
  },

  getVinStat() {
    const params = {
      action: "stat"
    };
    return client.get("vin/vin.php", { params });
  }
};
