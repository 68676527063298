<template>
  <div class="vld-parent">
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="row" style="min-height: 300px">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h4 class="card-title">VIN</h4>
            Get car report
          </template>
          <div style="min-height: 300px">
            <form data-vv-scope="searchval">
              <div class="row">
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-sm-8 top-1">
                      <fg-input
                        placeholder="17-character VIN number"
                        v-model="search"
                        v-validate="modelValidations.vin"
                        name="VIN"
                        :error="getError('searchval.VIN')"
                      ></fg-input>
                    </div>
                    <div class="col-sm-4 top-2">
                      <n-button type="info" @click.native="validate">
                        Go
                      </n-button>
                    </div>
                  </div>
                  <div class="row" v-if="result.valid === 2">
                    <div class="col-12">
                      <h3>VIN is invalid!</h3>
                    </div>
                  </div>
                  <div v-if="result.valid === 1">
                    <div class="row bottom-5">
                      <div class="col-5"><b>Brand:</b></div>
                      <div class="col-7">{{ result.manufacturer }}</div>
                    </div>
                    <div class="row bottom-5">
                      <div class="col-5"><b>Region/Plant:</b></div>
                      <div class="col-7">{{ result.country }}</div>
                    </div>
                    <div class="row bottom-5">
                      <div class="col-5"><b>Year:</b></div>
                      <div class="col-7">{{ result.year }}</div>
                    </div>
                    <div class="row bottom-5">
                      <div class="col-5"><b>Details:</b></div>
                      <div class="col-7">{{ result.details }}</div>
                    </div>
                    <div class="row">
                      <div class="col-5"><b>Serial number:</b></div>
                      <div class="col-7">{{ result.serialNumber }}</div>
                    </div>

                    <div v-if="vin.status === 1">
                      <div class="row">
                        <div class="col-12">
                          <h4>
                            Accident report (Total {{ vin.accident.length }})
                          </h4>
                        </div>
                      </div>
                      <card
                        v-for="item in vin.accident"
                        :key="item.id"
                        style="margin-bottom: 15px"
                      >
                        <div class="row">
                          <div class="col-5">Insurance type</div>
                          <div class="col-7">
                            <b>{{ item.policytypename }}</b>
                          </div>
                          <div class="col-5">Date</div>
                          <div class="col-7">
                            <b>{{ item.accidentdate }}</b>
                          </div>

                          <div class="col-5">Amount of damage</div>
                          <div class="col-7">₼ {{ item.amount }}</div>

                          <div class="col-5">Lavel</div>
                          <div class="col-7">{{ item.levelname }}</div>

                          <div class="col-5">Description</div>
                          <div class="col-7">{{ item.descr }}</div>

                          <div class="col-5">Accident location</div>
                          <div class="col-7">{{ item.location }}</div>

                          <div class="col-5">Culprit</div>
                          <div class="col-7">{{ item.culprit }}</div>

                          <div class="col-5">Reason for not paying</div>
                          <div class="col-7">{{ item.nopaidreason }}</div>
                        </div>
                      </card>
                    </div>
                    <div class="row" v-else>
                      <div class="col-12">
                        <h4>
                          {{ vin.msg }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4" v-if="showrecent">
                  <div class="row">
                    <div class="col-6 top" style="padding-top: 10px">
                      Recently added
                    </div>
                    <div class="col-6 middle">
                      <n-button
                        type="primary"
                        block
                        @click.native="newAccident"
                      >
                        Add new accident
                      </n-button>
                    </div>
                  </div>
                  <div class="row" v-for="item in recent" :key="item.id">
                    <div class="col-8 font-size-12">
                      <a href="#/vin/#" @click="change(item)">
                        {{ item.vin }}</a
                      >
                    </div>
                    <div class="col-4 font-size-12">₼ {{ item.amount }}</div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </card>
        <!-- end card -->
      </div>
    </div>

    <modal :show.sync="modal" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up">Accident</h6>
      <template>
        <form data-vv-scope="modalval">
          <div class="row">
            <div class="col-12">
              <fg-input
                placeholder="VIN"
                v-model="editvin"
                v-validate="modelValidations.vinmodal"
                name="vin"
                :error="getError('modalval.vin')"
              ></fg-input>
            </div>
            <div
              style="padding-left: 20px"
              class="col-12"
              v-if="resultvin.valid === 1"
            >
              <div class="row">
                <div class="col-5 font-size-12">Brand:</div>
                <div class="col-7 font-size-12">
                  {{ resultvin.manufacturer }}
                </div>
              </div>
              <div class="row">
                <div class="col-5 font-size-12">Region/Plant:</div>
                <div class="col-7 font-size-12">{{ resultvin.country }}</div>
              </div>
              <div class="row">
                <div class="col-5 font-size-12">Year:</div>
                <div class="col-7 font-size-12">{{ resultvin.year }}</div>
              </div>
              <div class="row">
                <div class="col-5 font-size-12">Check digit:</div>
                <div class="col-7 font-size-12">{{ resultvin.checkdigit }}</div>
              </div>
              <div class="row">
                <div class="col-5 font-size-12">Sequential number:</div>
                <div class="col-7 font-size-12">{{ resultvin.seqnumber }}</div>
              </div>
            </div>

            <div class="col-12">
              <fg-input name="accidentdate">
                <el-date-picker
                  v-validate="modelValidations.accidentdate"
                  :error="getError('modalval.accidentdate')"
                  type="date"
                  placeholder="Accident date"
                  v-model="accident.accidentdate"
                >
                </el-date-picker>
              </fg-input>
            </div>
            <div class="col-12">
              <fg-input
                placeholder="Amount of damage"
                v-model="accident.amount"
                v-validate="modelValidations.amount"
                name="amount"
                :error="getError('modalval.amount')"
              ></fg-input>
            </div>
            <div class="col-12">
              <el-select
                class="select-default btn-block"
                size="large"
                placeholder="Level"
                name="level"
                v-model="accident.level"
                data-vv-validate-on="change"
                v-validate="modelValidations.level"
                :error="getError('modalval.level')"
              >
                <el-option label="Level 1" :value="1"></el-option>
                <el-option label="Level 2" :value="2"></el-option>
                <el-option label="Level 3" :value="3"></el-option>
                <el-option label="Level 4" :value="4"></el-option>
              </el-select>
            </div>
            <div class="col">
              <n-button type="danger" @click.native="del" v-if="accident.id">
                Delete record</n-button
              >
            </div>
            <div class="col-auto">
              <n-button type="success" @click.native="save">
                Save changes
              </n-button>
            </div>
          </div>
        </form>
      </template>
    </modal>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Modal } from "src/components";
import { vinDecoder } from "src/services/vin-decode";
import VinService from "src/services/vin";
import { FormGroupInput as FgInput } from "src/components/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option,
} from "element-ui";
export default {
  components: {
    FgInput,
    Loading,
    Modal,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      isLoading: false,
      search: "",
      showrecent: false,
      result: {
        valid: 0,
      },
      vin: {
        accident: [],
        status: 0,
        msg: "",
      },
      recent: [],
      accident: {
        level: 1,
      },
      modal: false,
      editvin: "",
      resultvin: {
        valid: 0,
      },
      modelValidations: {
        vin: {
          required: true,
          length: 17,
        },
        vinmodal: {
          required: true,
        },
        amount: {
          required: true,
          min_value: 1,
          max_value: 10000,
          numeric: true,
        },
      },
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.result.valid = 0;

      this.$validator.validateAll("searchval").then((isValid) => {
        if (isValid) {
          this.search = this.search.toUpperCase();
          this.result.search = this.search;
          this.result = vinDecoder(this.search).decode();
          this.result.valid = 1;
          if (!this.$store.state.user) {
            this.vin.msg = "No token!";
            return;
          }
          this.isLoading = true;
          VinService.getVinAccident(
            this.search,
            this.$store.state.user.id
          ).then((response) => {
            this.isLoading = false;
            if (response.data.result) {
              if (response.data.result === "-1") {
                this.vin.status = 2;
                this.vin.msg = "You don't have permissions!";
              } else {
                this.vin.status = 1;
                this.vin.accident = response.data.result;
              }
            } else {
              this.vin.status = 3;
              this.vin.msg = "No data found!";
            }
          });
        }
      });
    },
    newAccident() {
      this.modal = true;
      this.editvin = "";
      this.accident = { level: 1 };
    },

    change(item) {
      this.modal = true;
      this.editvin = item.vin;
      this.accident = Object.assign({}, item);
    },
    save() {
      this.$validator.validateAll("modalval").then((isValid) => {
        this.accident.uid = this.$store.state.user.id;
        if (isValid) {
          this.isLoading = true;
          if (!this.accident.id) {
            this.accident.vin = this.editvin;
            CostService.insertVinAccident(this.accident).then((response) => {
              this.isLoading = false;
              console.log(response.data);
              if (response.data.result) {
                this.modal = false;
                this.getRecents();
              }
            });
          } else {
            CostService.updateVinAccident(this.accident).then((response) => {
              this.isLoading = false;
              if (response.data.result) {
                this.modal = false;
                this.getRecents();
              }
            });
          }
        }
      });
    },
    del() {
      CostService.deleteVinAccident(this.accident).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.modal = false;
        }
      });
    },

    getRecents() {
      // this.isLoading = true;
      // CostService.getVinAccidentRecent(this.$store.state.user.id).then(
      //   (response) => {
      //     this.isLoading = false;
      //     if (response.data.result) {
      //       this.recent = response.data.result;
      //     }
      //   }
      // );
    },
  },
  mounted() {
    this.getRecents();
  },
  watch: {
    editvin: function (value) {
      this.resultvin = VinDecode(value);
      if (!this.resultvin) this.resultvin = { valid: 0 };
    },
  },
};
</script>
<style>
.middle {
  vertical-align: middle;
  display: table-cell;
}
.top-1 {
  vertical-align: top;
  display: table-cell;
  padding-top: 10px;
}
.top-2 {
  vertical-align: top;
  display: table-cell;
}
.bottom-5 {
  margin-bottom: 5px;
}
.font-size-12 {
  font-size: 12px;
}
</style>