import axios from "axios";
import Vue from "vue";

const client = axios.create({
  baseURL: Vue.prototype.$apiUrl,
  withCredentials: false
});

const py = axios.create({
  baseURL: Vue.prototype.$pyUrl,
  withCredentials: false
});

client.interceptors.request.use(config => {
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

export default {
  getPropertyCost(property) {
    var floor, totalfloor;
    if (property.floor === "") floor = 8;
    else floor = property.floor;
    if (property.totalfloor === "") totalfloor = 16;
    else totalfloor = property.totalfloor;
    return py.get(
      "PropertyEstimate/" +
        property.square +
        "/" +
        property.room +
        "/" +
        floor +
        "/" +
        totalfloor +
        "/" +
        property.repair +
        "/" +
        property.doc +
        "/" +
        property.lat +
        "/" +
        property.lng
    );
  },

  getMotorCost(motor) {
    return py.get(
      "MotorEstimate/" +
        motor.brand +
        "/" +
        motor.model +
        "/" +
        motor.wd +
        "/" +
        motor.year +
        "/" +
        motor.distance +
        "/" +
        motor.color +
        "/" +
        motor.engine +
        "/" +
        motor.fuel +
        "/" +
        motor.trans
      //  + "/" +
      // motor.alg
    );
  },

  getMotorStat(motor) {
    return py.get(
      "MotorAnalitics/" +
        motor.model +
        "/" +
        motor.year +
        "/" +
        motor.engine +
        "/" +
        motor.fuel
    );
  },

  insertPropertyLog(log) {
    return client.post("property/create.php", log);
  },

  updatePropertyLog(log) {
    return client.put("property/update.php", log);
  },

  offerPropertyLog(log) {
    return client.put("property/offer.php", log);
  },

  errorPropertyLog(log) {
    return client.put("property/error.php", log);
  },
  statLog() {
    return client.get("stat.php");
  },
  getPlaces() {
    return client.get("property/places.php");
  },
  getDistrict() {
    return client.get("property/district.php");
  },
  getDistrictPlace(name) {
    return client.get("property/distplace.php?name=" + name);
  },
  getBrand() {
    return client.get("motor/brand.php");
  },
  getModel(val) {
    return client.get("motor/model.php?id=" + val);
  },
  getModelEngine(val) {
    return client.get("motor/modelengine.php?id=" + val);
  },
  getModelWd(val) {
    return client.get("motor/modelwd.php?id=" + val);
  },
  getModelTrans(val) {
    return client.get("motor/modeltrans.php?id=" + val);
  },
  getColor() {
    return client.get("motor/color.php");
  },
  getFuel() {
    return client.get("motor/fuel.php");
  },
  insertMotorLog(log) {
    return client.post("motor/create.php", log);
  },
  updateMotorLog(log) {
    return client.put("motor/update.php", log);
  },

  offerMotorLog(log) {
    return client.put("motor/offer.php", log);
  },

  errorMotorLog(log) {
    return client.put("motor/error.php", log);
  },

};
