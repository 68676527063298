<template>
  <div class="panel-header h-20">
    
  </div>
</template>

<script>
export default {
  name: 'overview-header'
};
</script>

<style scoped>
</style>
