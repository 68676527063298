<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-stats card-raised">
          <div class="card-body">
            <div>
              <h5>
                Dear guest,
                <small>
                  <br />HUEstimates.Cost is a set of online services for
                  forecasting, searching, filtering and analyzing various
                  indicators of the financial market of Azerbaijan using Machine
                  Learning models and statistical analysis methods.
                  <br />
                </small>
              </h5>
              <footer class="blockquote-footer">Hafiz Salamov</footer>
            </div>
            <br />
            <div class="row">
              <div class="col-12">
                <h4>Predicting services requests</h4>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-primary">
                      <i class="now-ui-icons arrows-1_cloud-upload-94"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="stat.total"></animated-number>
                    </h3>
                    <h6 class="stats-title">Total request count</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <i class="now-ui-icons ui-2_like"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="stat.success"></animated-number>
                    </h3>
                    <h6 class="stats-title">Successes</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-info">
                      <i class="now-ui-icons users_single-02"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number
                        :value="stat.corrected"
                      ></animated-number>
                    </h3>
                    <h6 class="stats-title">Corrected</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-danger">
                      <i class="now-ui-icons business_briefcase-24"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="3"></animated-number>
                    </h3>
                    <h6 class="stats-title">Models</h6>
                  </div>
                </div>
              </div>
              <br />
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6">
                    <h6>Special thanks to my people.</h6>

                    <br />TM (Data analyst) <br />Anar Aliyev (ML consultant)
                    <br />Tural Mustafayev (Market consultant) <br />Agshin
                    Madatov (Testing and verification of predictions)
                    <br />Tunjay Huseynzadeh (UI/UX consultant)
                    <br />
                    <small class="text-muted"
                      >I appreciate your participation and attention</small
                    >
                  </div>
                  <div class="col-md-6">
                    <h6>Contributors</h6>
                    <a href="https://asa.az/home" target="_blank">
                      <img src="/img/asa.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h4>VIN Lookup requests</h4>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <i class="now-ui-icons ui-2_like"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="vin.size"></animated-number>
                    </h3>
                    <h6 class="stats-title">Size</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="statistics">
                  <div class="info">
                    <div class="icon icon-success">
                      <i class="now-ui-icons ui-2_like"></i>
                    </div>
                    <h3 class="info-title">
                      <animated-number :value="vin.total"></animated-number>
                    </h3>
                    <h6 class="stats-title">Total request count</h6>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                    <h6>Sponsors</h6>
                    <a href="https://asa.az/home" target="_blank">
                      <img src="/img/asa.png" />
                    </a>
                  </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  StatsCard,
  Card,
  Table as NTable,
  Checkbox,
  AnimatedNumber,
  Progress as NProgress,
  AsyncWorldMap,
} from "src/components";

import LineChart from "src/components/Charts/LineChart";
import CostService from "src/services/cost.js";
import VinService from "src/services/vin.js";
export default {
  components: {
    Checkbox,
    Card,
    NTable,
    StatsCard,
    AnimatedNumber,
    LineChart,
    NProgress,
    AsyncWorldMap,
  },
  data() {
    return {
      progress: 0,
      vin: {
        size: 0,
        total: 0
      },
      stat: {
        total: 0,
        success: 0,
        corrcted: 0,
      },
    };
  },
  created() {
    CostService.statLog().then((response) => {
      if (response.data === null) {
        this.showSwal("error-message");
      } else {
        this.stat.total = response.data.total;
        this.stat.success = response.data.success;
        this.stat.corrected = response.data.corrected;
      }
    });

    VinService.getVinStat().then((response) => {
      if (response.data === null) {
      } else {
        this.vin.total = response.data.result.total;
        this.vin.size = response.data.result.size;
      }
    });
  },
};
</script>
<style>
</style>
