<template>
  <div class="vld-parent">
    <loading name="lp" :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <div class="row">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h4 class="card-title">Vehicle</h4>Select a car's features bellow and click Predict button
          </template>
          <div>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block no-touch"
                        size="large"
                        placeholder="Brand ..."
                        @change="brandChanged"
                        name="brand"
                        v-model="filterval.brand"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.brand"
                        :error="getError('brand')"
                      >
                        <el-option
                          v-for="item in brands"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('brand')">{{getError('brand')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Model ..."
                        name="model"
                        @change="modelChanged"
                        v-model="filterval.model"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.model"
                        :error="getError('model')"
                      >
                        <el-option
                          v-for="item in models"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('model')">{{getError('model')}}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Transmission ..."
                        v-model="filterval.trans"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.trans"
                        name="transmission"
                        :error="getError('transmission')"
                      >
                        <el-option
                          v-for="item in trans"
                          class="select-primary"
                          :value="item.id"
                          :label="item.name"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                      <label
                        class="text-danger"
                        v-if="getError('transmission')"
                      >{{getError('transmission')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Fuel ..."
                        v-model="filterval.fuel"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.fuel"
                        name="fuel"
                        :error="getError('fuel')"
                      >
                        <el-option
                          v-for="item in fuel"
                          class="select-primary"
                          :value="item.id"
                          :label="item.name"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('fuel')">{{getError('fuel')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Engine volume ..."
                        v-model="filterval.engine"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.vol"
                        name="vol"
                        :error="getError('vol')"
                      >
                        <el-option
                          v-for="item in vols"
                          class="select-primary"
                          :value="item.id"
                          :label="item.vol"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('vol')">{{getError('vol')}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Wheel drive ..."
                        v-model="filterval.wd"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.wd"
                        name="wd"
                        :error="getError('wd')"
                      >
                        <el-option
                          v-for="item in wds"
                          class="select-primary"
                          :value="item.id"
                          :label="item.name"
                          :key="item.id"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('wd')">{{getError('wd')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Color ..."
                        name="color"
                        v-model="filterval.color"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.color"
                        :error="getError('color')"
                      >
                        <el-option
                          v-for="item in colors"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('color')">{{getError('color')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Production year ..."
                        v-model="filterval.year"
                        data-vv-validate-on="change"
                        v-validate="modelValidations.year"
                        name="year"
                        :error="getError('year')"
                      >
                        <el-option
                          v-for="(i, index) in (modelyears)"
                          class="select-primary"
                          :value="i"
                          :label="i"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('year')">{{getError('year')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <fg-input
                        placeholder="Odometer ..."
                        v-model="filterval.distance"
                        v-validate="modelValidations.dist"
                        name="odometer"
                        :error="getError('odometer')"
                      ></fg-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-8">
                      <n-button type="info" block @click.native="validate">Predict</n-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-8">
                      <n-button type="success" block @click.native="statistics">Statistics</n-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </card>
        <!-- end card -->
      </div>
    </div>

    <modal :show.sync="modals.classic" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up">Prediction</h6>
      <template>
        <div class="row">
          <div class="col-lg-12 col-md-12 text-center">
            <card
              class="card-contributions"
              :title="cost.display | formatNumber0"
              sub-title="manats"
            >
              <div class="row" slot="footer" v-if="!editmode">
                <div class="col-6">
                  <div class="card-stats justify-content-center">
                    <n-button type="danger" icon round @click.native="onDislikeClicked">
                      <i class="fa fa-thumbs-o-down"></i>
                    </n-button>
                    <span>Result is far from reality</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card-stats justify-content-center">
                    <n-button type="info" icon round @click.native="onSaveClicked">
                      <i class="fa fa-thumbs-o-up"></i>
                    </n-button>
                    <span>Like prediction</span>
                  </div>
                </div>
              </div>
              <div class="form-row align-items-center" v-if="editmode">
                <div class="col-3">Your price</div>
                <div class="col-6">
                  <fg-input
                    v-model="offer.userprice"
                    v-validate="modelValidations.cost"
                    name="Price"
                    :error="getError('Price')"
                  ></fg-input>
                </div>
                <div class="col-3">
                  <n-button type="success" icon @click.native="onSaveClicked">
                    <i class="fa fa-check"></i>
                  </n-button>
                  <n-button type="danger" icon @click.native="onCancelClicked">
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </template>
    </modal>

    <el-dialog :visible.sync="modals.stat" :fullscreen="true">
      <div ref="content">
        <div class="row">
          <div class="col-sm-10">
            <h4>Vehicle: {{statlabel.brand}} {{statlabel.model}}, {{statlabel.engine.toFixed(1)}} {{statlabel.fuel}}, {{statlabel.year}}</h4>
            <div class="row">
              <div class="col-sm-10">
                <h6>General price statistics for {{statlabel.brand}} {{statlabel.model}}, {{statlabel.engine.toFixed(2)}} {{statlabel.fuel}}</h6>
                <div class="row">
                  <div class="col-sm-4 col-6">Population size</div>
                  <div class="col-sm-8 col-6">{{stat.size}}</div>
                  <div class="col-sm-4 col-6">Standard deviation</div>
                  <div class="col-sm-8 col-6">{{stat.std === -1 ? "N/A" : stat.std}}</div>
                  <div class="col-sm-4 col-6">Mean</div>
                  <div class="col-sm-8 col-6">{{stat.mean === -1 ? "N/A" : stat.mean}}</div>
                  <div class="col-sm-4 col-6">Max</div>
                  <div class="col-sm-8 col-6">{{stat.max === -1 ? "N/A" : stat.max}}</div>
                  <div class="col-sm-4 col-6">Min</div>
                  <div class="col-sm-8 col-6">{{stat.min === -1 ? "N/A" : stat.min}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-2" style="text-align: center">
            <div>
              <img id="i1" name="i1" :src="'hu-32.png'" />
              <br />
              <span>
                report by
                <br />cost.huestimates.app
              </span>
            </div>
            <n-button
              v-if="statbuttonsvisible"
              type="default"
              block
              @click.native="generateReport"
            >PDF</n-button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div style="text-align: center">
                  <h6>The relationship between Price and Production Year</h6>
                  <small>Whole population</small>
                </div>
                <img :src="'data:image/png;base64,' + stat.plt_year_price" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 col-6">Pearson corr coef</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.pearson_year_price === -2 ? "N/A" : stat.pearson_year_price}}</div>
              <div class="col-sm-4 col-6">Pearson p-value</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.pearson_pvalue_year_price === -1 ? "N/A" :stat.pearson_pvalue_year_price}}</div>
              <div class="col-sm-4 col-6">Spearman corr coef</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.spearman_year_price === -2 ? "N/A" : stat.spearman_year_price}}</div>
              <div class="col-sm-4 col-6">Spearman p-value</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.spearman_pvalue_year_price === -1 ? "N/A" :stat.spearman_pvalue_year_price}}</div>
              <div class="col-sm-4 col-6">Corr coef significance</div>
              <div class="col-sm-8 col-6">
                {{stat.pearson_year_price === -2 ? "N/A" : stat.corr_pvalue_year_price}}
                <span
                  style="padding-left: 10px; color: green"
                  v-if="stat.corr_pvalue_year_price < 0.05"
                >Significant linear relation</span>
                <span
                  style="padding-left: 10px; color: red"
                  v-if="stat.corr_pvalue_year_price >= 0.05"
                >There is no significant linear relation</span>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div style="text-align: center">
                  <h6>The relationship between Price and Distance</h6>
                  <small>Whole population</small>
                </div>
                <img :src="'data:image/png;base64,' + stat.plt_dist_price" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 col-6">Pearson corr coef</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.pearson_dist_price === -2 ? "N/A" : stat.pearson_dist_price}}</div>
              <div class="col-sm-4 col-6">Pearson p-value</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.pearson_pvalue_dist_price === -1 ? "N/A" :stat.pearson_pvalue_dist_price}}</div>
              <div class="col-sm-4 col-6">Spearman corr coef</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.spearman_dist_price === -2 ? "N/A" : stat.spearman_dist_price}}</div>
              <div class="col-sm-4 col-6">Spearman p-value</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.spearman_pvalue_dist_price === -1 ? "N/A" :stat.spearman_pvalue_dist_price}}</div>
              <div class="col-sm-4 col-6">Corr coef significance</div>
              <div class="col-sm-8 col-6">
                {{stat.pearson_dist_price === -2 ? "N/A" : stat.corr_pvalue_dist_price}}
                <span
                  style="padding-left: 10px; color: green"
                  v-if="stat.corr_pvalue_dist_price < 0.05"
                >Significant linear relation</span>
                <span
                  style="padding-left: 10px; color: red"
                  v-if="stat.corr_pvalue_dist_price >= 0.05"
                >There is no significant linear relation</span>
              </div>
              <div class="col-sm-4 col-6">Welch’s t-test</div>
              <div class="col-sm-8 col-6">{{stat.ttest_stat_dist_price}}</div>
              <div class="col-sm-4 col-6">p-value</div>
              <div class="col-sm-8 col-6">{{stat.ttest_pvalue_dist_price}}</div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div style="text-align: center">
                  <h6>Price distribution for {{filterval.year}}</h6>
                  <small>{{statlabel.brand}} {{statlabel.model}}, {{statlabel.engine.toFixed(1)}} {{statlabel.fuel}}, {{statlabel.year}}</small>
                </div>
                <img :src="'data:image/png;base64,' + stat.distplt_price" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 col-6">Sample size</div>
              <div class="col-sm-8 col-6">{{stat.size_for_year === -1 ? "N/A" : stat.size_for_year}}</div>
              <div class="col-sm-4 col-6">Standard deviation</div>
              <div class="col-sm-8 col-6">{{stat.std_for_year === -1 ? "N/A" : stat.std_for_year}}</div>
              <div class="col-sm-4 col-6">Mean</div>
              <div class="col-sm-8 col-6">{{stat.mean_for_year === -1 ? "N/A" : stat.mean_for_year}}</div>
              <div class="col-sm-4 col-6">Median</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.median_for_year === -1 ? "N/A" : stat.median_for_year}}</div>
              <div class="col-sm-4 col-6">Max</div>
              <div class="col-sm-8 col-6">{{stat.max_for_year === -1 ? "N/A" : stat.max_for_year}}</div>
              <div class="col-sm-4 col-6">Min</div>
              <div class="col-sm-8 col-6">{{stat.min_for_year === -1 ? "N/A" : stat.min_for_year}}</div>

              <div class="col-sm-4 col-6">Shapiro-Wilk test</div>
              <div
                class="col-sm-8 col-6"
              >{{stat.shapiro_pvalue_price === -1 & stat.shapiro_stat_price === -1 ? "N/A" : stat.shapiro_stat_price}}</div>
              <div class="col-sm-4 col-6">p-value</div>
              <div class="col-sm-8 col-6">
                {{stat.shapiro_pvalue_price === -1 & stat.shapiro_stat_price === -1 ? "N/A" : stat.shapiro_pvalue_price}}
                <span
                  style="padding-left: 10px; color: red"
                  v-if="stat.shapiro_pvalue_price < 0.05"
                >Normality violated</span>
                <span
                  style="padding-left: 10px; color: green"
                  v-if="stat.shapiro_pvalue_price >= 0.05"
                >Normal distributed</span>
              </div>
              <div class="col-sm-4 col-6">
                Confidence Interval
                <Br />(price - 95%)
              </div>
              <div class="col-sm-8 col-6">{{stat.interval95.from}} - {{stat.interval95.to}}</div>
              <div class="col-sm-4 col-6">
                Confidence Interval
                <Br />(price - 68%)
              </div>
              <div class="col-sm-8 col-6">{{stat.interval68.from}} - {{stat.interval68.to}}</div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div style="text-align: center">
                  <h6>The relationship between Price and Distance for {{statlabel.year}}</h6>
                  <small>{{statlabel.brand}} {{statlabel.model}}, {{statlabel.engine.toFixed(1)}} {{statlabel.fuel}}, {{statlabel.year}}</small>
                </div>
                <img :src="'data:image/png;base64,' + stat.regplt_dist_price" />
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-sm-12">
                <div style="text-align: center">
                  <h6>Feature importance</h6>
                  <small>Whole population</small>
                </div>
                <img :src="'data:image/png;base64,' + stat.barplt_features" />
              </div>
            </div>
          </div>
          <div v-if="statbuttonsvisible" class="col-sm-12">
            <n-button type="default" block @click.native="modals.stat = false">Close</n-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import jsPDF from "jspdf";

import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option,
  Dialog,
} from "element-ui";

import swal from "sweetalert2";
import { Modal } from "src/components";
import CostService from "src/services/cost.js";
import Dropdown from "vue-simple-search-dropdown";
import {
  Checkbox,
  Radio,
  Progress,
  Switch,
  FormGroupInput as FgInput,
} from "src/components/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    FgInput,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch,
    [Progress.name]: Progress,
    [Dialog.name]: Dialog,
    Checkbox,
    Radio,
    Modal,
    Dropdown,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      logid: "",
      editmode: false,
      logo: "",
      // algs: ["Xgb", "Lgb"],
      fuel: [],
      trans: [],
      brands: [],
      models: [],
      colors: [],
      modelyears: [],
      wds: [],
      vols: [],
      filterval: {
        fuel: "",
        wd: "",
        brand: "",
        model: "",
        trans: "",
        color: "",
        engine: "",
        year: "",
        distance: "",
        uid: "",
      },
      statlabel: {
        brand: "",
        model: "",
        engine: 0,
        year: 0,
        fuel: 0,
      },
      offer: {
        id: "",
        status: 1,
        userprice: null,
      },
      cost: {
        id: "",
        price: "",
        display: "",
      },
      stat: {
        interval95: {},
        interval68: {},
      },
      statbuttonsvisible: true,
      errmsg: {
        id: "",
        err: "",
      },
      modals: {
        classic: false,
        stat: false,
      },
      modelValidations: {
        vol: {
          required: true,
          min_value: 1,
          max_value: 100,
          numeric: true,
        },
        year: {
          required: true,
          min_value: 1900,
          max_value: (new Date().getFullYear()),
          numeric: true,
        },
        dist: {
          required: true,
          min_value: 0,
          max_value: 999999,
          numeric: true,
        },
        wd: {
          required: true,
        },
        fuel: {
          required: true,
        },
        color: {
          required: true,
        },
        cost: {
          required: true,
          min_value: 1000,
          max_value: 2000000,
          numeric: true,
        },
        brand: {
          required: true,
        },
        model: {
          required: true,
        },
        trans: {
          required: true,
        },
      },
    };
  },
  methods: {
    generateReport() {
      const doc = new jsPDF();
      this.statbuttonsvisible = false;
      let filename =
        this.statlabel.brand +
        "_" +
        this.statlabel.model.replace("-", "") +
        "_" +
        this.statlabel.engine.toString().replace(".", "_") +
        "_" +
        this.statlabel.year;
      domtoimage
        .toPng(this.$refs.content)
        .then((dataUrl) => {
          var img = new Image();
          img.src = dataUrl;
          const doc = new jsPDF({
            orientation: "portrait",
            format: [350, 600],
            compress: true
          });

          doc.addImage(img, "JPEG", 20, 20);
          doc.save(filename);
          this.statbuttonsvisible = true;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
          this.statbuttonsvisible = true;
        });
    },
    showSwal(type) {
      if (type === "success-message") {
        swal({
          title: "Succeed!",
          text: "Thank you!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success btn-fill",
          type: "success",
        });
      } else if (type === "error-message") {
        swal({
          title: "Exception!",
          text: "Action failed!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-warning btn-fill",
          type: "warning",
        });
      } else if (type === "warning-message") {
        swal({
          title: "Warning!",
          text:
            "Please fill all required fields: Brand, model, fuel, engine volume, production year!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-warning btn-fill",
        });
      } else if (type === "empty-message") {
        swal({
          title: "Warning!",
          text: "Not enough data for reporting!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-warning btn-fill",
        });
      }
    },
    brandChanged(value) {
      this.filterval.fuel = "";
      this.filterval.wd = "";
      this.filterval.model = "";
      this.filterval.trans = "";
      this.filterval.color = "";
      this.filterval.engine = "";
      this.filterval.year = "";
      this.filterval.uid = "";
      this.wds = [];
      this.vols = [];
      this.trans = [];
      this.models = [];
      CostService.getModel(value).then((response) => {
        if (response.data !== null) {
          this.models = response.data.result;
        }
      });
    },
    modelChanged(value) {
      this.filterval.wd = "";
      this.filterval.vol = "";
      this.filterval.trans = "";
      this.wds = [];
      this.vols = [];
      this.trans = [];
      CostService.getModelWd(value).then((response) => {
        if (response.data !== null) {
          this.wds = response.data.result;
        }
      });
      CostService.getModelEngine(value).then((response) => {
        if (response.data !== null) {
          this.vols = response.data.result;
        }
      });
      CostService.getModelTrans(value).then((response) => {
        if (response.data !== null) {
          this.trans = response.data.result;
        }
      });

      var valobj = this.models.filter(function (e) {
        if (e.id == value) return e;
      });
      var sm = valobj[0];
      if (sm !== null) {
        if (sm.maxyear === "0") sm.maxyear = new Date().getFullYear();
        if (sm.minyear === "0") sm.minyear = 1990;
        var i;
        this.modelyears = [];
        for (i = sm.minyear; i <= sm.maxyear; i++) {
          this.modelyears.push(i);
        }
      }
    },
    onResultClicked() {
      this.isLoading = true;
      this.filterval.uid = this.$store.getters.demouser;
      CostService.insertMotorLog(this.filterval).then((response) => {
        if (response.data === null) {
          this.showSwal("error-message");
        } else {
          this.editmode = false;
          this.logid = response.data.id;
          CostService.getMotorCost(this.filterval).then((costresponse) => {
            if (costresponse.data === null) {
              this.showSwal("error-message");
              this.errmsg.id = this.logid;
              this.errmsg.err = "Exception";
              CostService.errorMotorLog(this.errmsg).then();
              this.isLoading = false;
            } else {
              this.cost.price = costresponse.data.result.toString();
              this.cost.id = this.logid;
              this.cost.display =
                Math.round(costresponse.data.result / 10) * 10;
              CostService.updateMotorLog(this.cost).then();
              this.modals.classic = true;
              this.isLoading = false;
            }
          });
        }
      });
    },
    onDislikeClicked() {
      this.editmode = true;
    },
    onCancelClicked() {
      this.editmode = false;
    },
    onSaveClicked() {
      this.offer.id = this.logid;
      if (this.editmode) {
        this.offer.status = 2;
      } else {
        this.offer.userprice = null;
        this.offer.status = 1;
      }
      CostService.offerMotorLog(this.offer).then((response) => {
        if (response.data === null) {
          this.showSwal("error-message");
        } else {
          this.showSwal("success-message");
          this.modals.classic = false;
        }
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) this.onResultClicked();
      });
    },
    statistics() {
      if (
        !this.filterval.model ||
        !this.filterval.year ||
        !this.filterval.engine ||
        !this.filterval.fuel
      ) {
        this.showSwal("warning-message");
        return;
      }
      this.isLoading = true;
      this.filterval.uid = this.$store.getters.userid;
      this.statbuttonsvisible = true;
      CostService.getMotorStat(this.filterval).then((costresponse) => {
        this.isLoading = false;
        if (!costresponse.data.result) {
          this.showSwal("error-message");
          this.errmsg.id = this.logid;
          this.errmsg.err = "Exception";
          CostService.errorMotorLog(this.errmsg).then();
        } else {
          
          if (costresponse.data.result.size === 0) {
            this.showSwal("empty-message");
            return;
          }
          this.statlabel.brand = this.brands.filter(
            (c) => c.id === this.filterval.brand
          )[0].name;
          this.statlabel.model = this.models.filter(
            (c) => c.id === this.filterval.model
          )[0].name;
          this.statlabel.fuel = this.fuel.filter(
            (c) => c.id === this.filterval.fuel
          )[0].name;
          this.statlabel.year = this.filterval.year;
          this.statlabel.engine = this.filterval.engine / 10;
          this.stat = JSON.parse(costresponse.data.result);
          this.stat.plt_dist_price = this.stat.plt_dist_price.substring(
            1,
            this.stat.plt_dist_price.length - 1
          );
          this.stat.plt_year_price = this.stat.plt_year_price.substring(
            1,
            this.stat.plt_year_price.length - 1
          );
          this.stat.distplt_price = this.stat.distplt_price.substring(
            1,
            this.stat.distplt_price.length - 1
          );
          this.stat.barplt_features = this.stat.barplt_features.substring(
            1,
            this.stat.barplt_features.length - 1
          );
          this.stat.regplt_dist_price = this.stat.regplt_dist_price.substring(
            1,
            this.stat.regplt_dist_price.length - 1
          );
          this.modals.stat = true;
          
        }
      });
    },
  },
  created() {},
  mounted() {
    CostService.getBrand().then((response) => {
      if (response.data !== null) {
        this.brands = response.data.result;
      }
    });
    CostService.getColor().then((response) => {
      if (response.data !== null) {
        this.colors = response.data.result;
      }
    });
    CostService.getFuel().then((response) => {
      if (response.data !== null) {
        this.fuel = response.data.result;
      }
    });
  },
};
</script>

<style>
.plt {
  width: 350px;
  height: 350px;
}
</style>