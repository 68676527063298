<template>
  <div class="vld-parent">
    <loading name="lp" :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    <div class="row">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h4 class="card-title">Appartment</h4>Fill an appartment's form bellow and click on Predict button
          </template>
          <div>
            <form method="get" action="/" class="form-horizontal">
              <div class="row">
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-sm-12">
                      <fg-input
                        placeholder="Total area ..."
                        v-model="filterval.square"
                        v-validate="modelValidations.square"
                        name="area"
                        :error="getError('area')"
                      ></fg-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Rooms ..."
                        v-model="filterval.room"
                        v-validate="modelValidations.room"
                        name="room"
                        :error="getError('room')"
                      >
                        <el-option
                          v-for="(i, index) in maxroom"
                          class="select-primary"
                          :value="i"
                          :label="i + ' rooms'"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('room')">{{getError('room')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Floor ..."
                        v-model="filterval.floor"
                        @change="floorChanged"
                        v-validate="modelValidations.floor"
                        name="floor"
                        :error="getError('floor')"
                      >
                        <el-option
                          v-for="(i, index) in (maxfloor)"
                          class="select-primary"
                          :value="i"
                          :label="i + ' floor'"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('floor')">{{getError('floor')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        id="totalFloorSelect"
                        placeholder="Total floors ..."
                        :disabled="filterval.floor === ''"
                        v-model="filterval.totalfloor"
                        v-validate="modelValidations.totalfloor"
                        name="totlaFloors"
                        :error="getError('totlaFloors')"
                      >
                        <el-option
                          v-for="(i, index) in avfloor"
                          class="select-primary"
                          :value="i"
                          :label="i + ' mərtəbə'"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <label
                        class="text-danger"
                        v-if="getError('totlaFloors')"
                      >{{getError('totlaFloors')}}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Renovation ..."
                        v-model="filterval.repair"
                        v-validate="modelValidations.repair"
                        name="repair"
                        :error="getError('repair')"
                      >
                        <el-option
                          v-for="(i,index) in repairs"
                          class="select-primary"
                          :value="index"
                          :label="i"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <label class="text-danger" v-if="getError('repair')">{{getError('repair')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-select
                        class="select-primary btn-block"
                        size="large"
                        placeholder="Document ..."
                        v-model="filterval.doc"
                        v-validate="modelValidations.doc"
                        name="document"
                        :error="getError('document')"
                      >
                        <el-option
                          v-for="(i, index) in docs"
                          class="select-primary"
                          :value="index"
                          :label="i"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <label
                        class="text-danger"
                        v-if="getError('document')"
                      >{{getError('document')}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <el-select
                        v-model="district"
                        class="select-default btn-block"
                        size="large"
                        placeholder="District ..."
                        @change="districtChanged"
                      >
                        <el-option
                          v-for="item in districts"
                          :key="item.name"
                          :label="item.name"
                          :value="item.name"
                        ></el-option>
                      </el-select>
                    </div>
                    <div class="col-sm-12">
                      <el-select
                        v-model="place.id"
                        class="select-default btn-block"
                        size="large"
                        placeholder="Location ..."
                        @change="placeChanged"
                      >
                        <el-option
                          v-for="item in places"
                          :key="item.id"
                          :label="item.displayname"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </div>
                    <p
                      class="text-muted col-sm-12"
                    >Select location from the list above or choose your location on map.</p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div>
                    <GoogleMap @location-marked="locationMarked" ref="filtermap" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-5">
                  <n-button type="info" block @click.native="validate">
                    <i slot="label" class="now-ui-icons travel_info"></i>
                    Predict
                  </n-button>
                </div>
              </div>
            </form>
          </div>
        </card>
        <!-- end card -->
      </div>
    </div>

    <div class="row text-center">
      <div class="col-md-3"></div>
      <div class="col-md-6"></div>
    </div>

    <modal :show.sync="modals.classic" headerClasses="justify-content-center">
      <h6 slot="header" class="title title-up">Prediction</h6>
      <template>
        <div class="row">
          <div class="col-lg-12 col-md-12 text-center">
            <card
              class="card-contributions"
              :title="cost.display | formatNumber0"
              sub-title="manats per square metre"
            >
              <div class="row" slot="footer" v-if="!editmode">
                <div class="col-6">
                  <div class="card-stats justify-content-center">
                    <n-button type="danger" icon round @click.native="onDislikeClicked">
                      <i class="fa fa-thumbs-o-down"></i>
                    </n-button>
                    <span>Result is far from reality</span>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card-stats justify-content-center">
                    <n-button type="info" icon round @click.native="onSaveClicked">
                      <i class="fa fa-thumbs-o-up"></i>
                    </n-button>
                    <span>Like prediction</span>
                  </div>
                </div>
              </div>
              <div class="form-row align-items-center" v-if="editmode">
                <div class="col-3">Your price</div>
                <div class="col-6">
                  <fg-input
                    v-model="offer.userCost"
                    v-validate="modelValidations.cost"
                    name="Cost"
                    :error="getError('Cost')"
                  ></fg-input>
                </div>
                <div class="col-3">
                  <n-button type="success" icon @click.native="onSaveClicked">
                    <i class="fa fa-check"></i>
                  </n-button>
                  <n-button type="danger" icon @click.native="onCancelClicked">
                    <i class="fa fa-times"></i>
                  </n-button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from "element-ui";

import swal from "sweetalert2";
import { Modal } from "src/components";
import CostService from "src/services/cost.js";
import GoogleMap from "./GoogleMaps";
import Dropdown from "vue-simple-search-dropdown";
import {
  Checkbox,
  Radio,
  Progress,
  Switch,
  FormGroupInput as FgInput
} from "src/components/index";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    FgInput,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Switch.name]: Switch,
    [Progress.name]: Progress,
    Checkbox,
    Radio,
    GoogleMap,
    Modal,
    Dropdown,
    Loading
  },
  data() {
    return {
      isLoading: false,
      maxroom: 9,
      maxfloor: 27,
      avfloor: [],
      repairs: ["no", "medium", "good"],
      docs: ["contract", "certificate"],
      logid: "",
      editmode: false,
      districts: [],
      district: "",
      places: [],
      place: {},
      filterval: {
        square: "",
        room: "",
        floor: "",
        totalfloor: "",
        repair: "",
        doc: "",
        lat: "",
        lng: ""
      },
      offer: {
        id: "",
        status: 1,
        userCost: null
      },
      cost: {
        id: "",
        predictedCost: "",
        display: "",
        costFrom: null,
        costTo: null
      },
      errmsg: {
        id: "",
        err: ""
      },
      modals: {
        classic: false
      },
      modelValidations: {
        room: {
          required: true,
          min_value: 1,
          max_value: 9,
          numeric: true
        },
        // floor: {
        //   required: true
        // },
        square: {
          required: true,
          min_value: 30,
          max_value: 400,
          numeric: true
        },
        cost: {
          required: true,
          min_value: 100,
          max_value: 10000,
          numeric: true
        },
        // totalfloor: {
        //   required: true
        // },
        repair: {
          required: true
        },
        doc: {
          required: true
        }
      }
    };
  },
  methods: {
    showSwal(type) {
      if (type === "success-message") {
        swal({
          title: "Succeed!",
          text: "Thank you!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success btn-fill",
          type: "success"
        });
      } else if (type === "error-message") {
        swal({
          title: "Exception!",
          text: "Action failed!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-warning btn-fill",
          type: "warning"
        });
      }
    },
    floorChanged() {
      this.avfloor = [];
      var j = 0;
      if (this.filterval.floor !== "") {
        for (var i = this.filterval.floor; i <= this.maxfloor; i++) {
          this.avfloor[j] = i;
          j++;
        }
        if (
          this.filterval.totalfloor !== "" &&
          this.filterval.floor > this.filterval.totalfloor
        ) {
          this.filterval.totalfloor = ""; // this.filterval.floor;
        }
      }
    },
    districtChanged(value) {
      this.place = {}
      CostService.getDistrictPlace(value).then(response => {
        if (response.data !== null) {

          this.places = response.data.result;
        }
      });
    },
    placeChanged(value) {
      var valobj = this.places.filter(function(e) {
        if (e.id == value) return e;
      });

      if (valobj.length > 0) {
        this.$refs.filtermap.moveto.lat = valobj[0].lat;
        this.$refs.filtermap.moveto.lng = valobj[0].lng;
        this.$refs.filtermap.setMapPosition();
      }
    },
    locationMarked(lat, lng) {
      this.filterval.lat = lat;
      this.filterval.lng = lng;
    },
    onResultClicked() {
      this.isLoading = true;
      CostService.insertPropertyLog(this.filterval).then(response => {
        if (response.data === null) {
          this.showSwal("error-message");
        } else {
          this.editmode = false;
          this.logid = response.data.id;

          CostService.getPropertyCost(this.filterval).then(costresponse => {
            if (costresponse.data === null) {
              this.showSwal("error-message");
              this.errmsg.id = this.logid;
              this.errmsg.err = "Exception";
              CostService.errorPropertyLog(this.errmsg).then();
              this.isLoading = false;
            } else {
              this.cost.predictedCost = costresponse.data.result.toString();
              this.cost.id = this.logid;
              this.cost.display =
                Math.round(costresponse.data.result / 10) * 10;
              CostService.updatePropertyLog(this.cost).then();
              this.modals.classic = true;
              this.isLoading = false;
            }
          });
        }
      });
    },
    onDislikeClicked() {
      this.editmode = true;
    },
    onCancelClicked() {
      this.editmode = false;
    },
    onSaveClicked() {
      this.offer.id = this.logid;
      if (this.editmode) {
        this.offer.status = 2;
      } else {
        this.offer.userCost = null;
        this.offer.status = 1;
      }
      CostService.offerPropertyLog(this.offer).then(response => {
        if (response.data === null) {
          this.showSwal("error-message");
        } else {
          this.showSwal("success-message");
          this.modals.classic = false;
        }
      });
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then(isValid => {
        if (isValid) this.onResultClicked();
      });
    }
  },
  mounted() {
    CostService.getDistrict().then(response => {
      if (response.data !== null) {
        
        this.districts = response.data.result;
      }
    });
  }
};
</script>

<style>
/* .el-select-dropdown__item {
  max-width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
} */
</style>