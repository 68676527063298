<template>
  <div class="row">
    <div class="col-md-12">
      <div id="regularMap" class="map" style="height: 470px"></div>
      
    </div>
  </div>
</template>
<script>
import { API_KEY } from "./API_KEY";
import GoogleMapsLoader from "google-maps";
GoogleMapsLoader.KEY = API_KEY;

export default {
  props: {
    moveto: {
      type: Object,
      default: function() {
        return { lat: "40.385238", lng: "49.8215883" };
      }
    }
  },
  data() {
    return {
      gmap: {},
      gzoom: 11,
      markers: []
    };
  },
  methods: {
    initRegularMap() {
      // Regular Map
      //var markers = [];
      let _this = this;
      const myLatlng = new window.google.maps.LatLng(
        this.moveto.lat,
        this.moveto.lng
      );
      const mapOptions = {
        zoom: this.gzoom,
        center: myLatlng,
        scrollwheel: false // we disable de scroll over the map, it is a really annoing when you scroll through page
      };

      const map = new window.google.maps.Map(
        document.getElementById("regularMap"),
        mapOptions
      );

      window.google.maps.event.addListener(map, "click", function(e) {
        //Determine the location where the user has clicked.
        var location = e.latLng;

        //Create a marker and placed it on the map.
        var marker = new google.maps.Marker({
          position: location,
          map: map
        });

        window.google.maps.event.addListener(marker, "click", function(e) {
          var infoWindow = new google.maps.InfoWindow({
            content:
              "Latitude: " +
              location.lat() +
              "<br />Longitude: " +
              location.lng()
          });
          infoWindow.open(map, marker);
        });
        _this.clearMarkers();
        //Add marker to the array.
        _this.markers.push(marker);

        _this.passToParent(location.lat(), location.lng());
      });

      this.gmap = map;
    },
    clearMarkers() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];
    },
    passToParent(lat, lng) {
      this.$emit("location-marked", lat, lng);
    },
    setMapPosition() {
      var location = new google.maps.LatLng(this.moveto.lat, this.moveto.lng);
      this.gmap.panTo(location);
      this.gmap.setZoom(15);

      var marker = new google.maps.Marker({
        position: location,
        map: this.gmap
      });

      this.clearMarkers();
      this.markers.push(marker);

      this.passToParent(location.lat(), location.lng());
    }
  },

  mounted() {
    GoogleMapsLoader.load(google => {
      this.initRegularMap(google);
    });
  }
};
</script>
<style lang="scss">
.card-map {
  min-height: 400px;
  .map {
    height: 450px;
    width: 100%;
  }
}
</style>
